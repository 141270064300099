import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ResumeLine } from "../components/resumeLine"

import "../styles/antiResume.scss"

const antiResume = {
  skills: [
    "Tough (on myself)",
    "Can be told what I can and cannot do",
    "Expert bike shedder",
    // Sunken cost fallacy
    // Trust falls
    // Feedback
    // "Probably won't tell you if something is bothering me",
    // Timeliness
    // Blame
    // Boundaries
    // Accountability
    // Attention to detail
  ],
  experience: [
    {
      title: "Was asked to pick an instrument, and picked the wrong one",
      age: 5,
      description: "I thought the French horn was called the clarinet.",
    },
    {
      title: "Gave up on clarinet",
      age: 8,
      description:
        "My parents made me play for 3 years. The contract came to an end. I did not renew.",
    },
    {
      title: "Gave up on table tennis",
      age: 15,
      description: "I played competitively but found it too frustrating",
    },
    {
      title: "Tried getting back into clarinet",
      age: 15,
    },
    {
      title: "Gave up on the clarinet, again",
      age: 16,
    },
    {
      title:
        "Got 5/20, 6/20, and 9/20 in biology, written French, and oral French respectively in my junior high school year for the baccalauréat",
      age: 17,
      description:
        "I made all the points back by getting 19/20 in physical education the following year",
    },
    {
      title: "Started playing World of Warcraft",
      age: 20,
    },
    {
      title: "Gave up on becoming a professional musician",
      age: 22,
    },
    {
      title: "Dropped out halfway into my masters program",
      age: 23,
    },
    {
      title: "Started playing Starcraft 2",
      age: 26,
      description:
        "Graduated from World of Warcraft university with a major in guild management, and minors in servers 5ths, and forum drama.",
    },
    {
      title: "Gave up on a career in business development",
      age: 30,
    },
    {
      title: "Got rejected from Hack Reactor.",
      age: 30,
      description: "Twice!",
    },
    {
      title: "Divorced",
      age: 31,
    },
    {
      title: (
        <span>
          Exercised{" "}
          <a
            href="https://techcrunch.com/2020/02/10/softbank-backed-brandless-shuts-its-doors-for-good/"
            target="_blank"
          >
            Brandless
          </a>{" "}
          stock options
        </span>
      ),
      age: 33,
    },
    {
      title: "Stopped climbing",
      age: 34,
      description: "Too many injuries",
    },
    {
      title: "Stopped being a homeowner",
      age: 35,
    },
    {
      title: "Stopped meditating",
      age: 35,
    },
  ].sort((line1, line2) => line1.age - line2.age),
}

const AntiResume = ({ location }: PageProps) => (
  <Layout location={location} className="AntiResume">
    <SEO title="Anti resume" />
    <div className="intro">
      <h2>Anti Resume</h2>
      <p>
        Behind every resume is an anti-resume, brimming with failures and
        setbacks.
      </p>
    </div>

    <div className="resume-section">
      <h3>Skills</h3>
      <ul>
        {antiResume.skills.map(skill => (
          <div>{skill}</div>
        ))}
      </ul>
    </div>

    <div className="resume-section">
      <h3>Experience</h3>
      <ul>
        {antiResume.experience.map(({ title, age, description }, index) => (
          <ResumeLine
            title={title}
            age={age}
            description={description}
            key={index}
          />
        ))}
      </ul>
    </div>
  </Layout>
)

export default AntiResume
