import React from "react"

import "../styles/resumeLine.scss"

interface ResumeLineProps {
  title: string | React.ReactElement
  age: number
  description?: string | React.ReactElement
}

export const ResumeLine = ({ title, age, description }: ResumeLineProps) => {
  return (
    <div className="ResumeLine">
      <div className="headline">
        <div className="text">
          <div className="title">{title}</div>
          {description && <div className="description">{description}</div>}
        </div>
        <div className="age">{age} years old</div>
      </div>
    </div>
  )
}
